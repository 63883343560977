/* LandingPage.css */

.glow {
  color: #fff;
  text-align: center;
  animation: glow 10s ease infinite;
}

@keyframes glow {
  0% {
    text-shadow: 0 0 10px #ffb5201d, 0 0 20px #ffb5201d, 0 0 30px #ffb5201d, 0 0 10px #ffb5201d;
  }
  
  100% {
    text-shadow: 0 0 10px #ffb5201d, 0 0 20px #ffb5201d, 0 0 30px #ffb5201d, 0 0 10px #ffb5201d;
  }

  
}

.glow-text {
  color: #fff;
  animation: glowText 5s ease-in-out;
}

@keyframes glowText {
  0% {
    text-shadow: 0 0 5px #000221, 0 0 10px #000221, 0 0 15px #000221, 0 0 20px #000221;
  }
  
  100% {
    text-shadow: 0 0 10px #000221, 0 0 20px #000221, 0 0 30px #000221, 0 0 40px #000221;
  }
}


.bg-dots {
  background-image: radial-gradient(#000 5%, transparent 5%), radial-gradient(#000 5%, transparent 5%);
  background-position: 0 0, 25px 25px;
  background-size: 25px 25px;
  height: 100%;
  width: 100%;
  opacity: 0.1;
  z-index: -1;
}

#ai, #cellTower, #human {
  text-align: center;
  margin: 30px;
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% { color: #eee; }
  50% { color: #09f; }
  100% { color: #eee; }
}

#cellTower:before {
  content: "📡";
  font-size: 30px;
  display: block;
}

#ai:before {
  content: "🤖";
  font-size: 30px;
  display: block;
}

#human:before {
  content: "👤";
  font-size: 30px;
  display: block;
}

.line {
  border-left: 2px dotted #09f;
  height: 60px;
  position: absolute;
  left: 50%;
}

#cellTower {
  position: relative;
}

#ai {
  position: absolute;
  top: 20px;
  left: 30px;
}

#human {
  position: absolute;
  top: 20px;
  right: 30px;
}

.signup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #15141D;
  color: #fff;
  font-family: Arial, sans-serif;
  padding: 1rem;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  padding: 2rem;
  background: #202020;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
}

.form-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-field {
  padding: 1rem;
  border: none;
  border-radius: 4px;
  margin-bottom: 1rem;
  background: #303030;
  color: #fff;
  transition: all 0.3
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-text {
  background: linear-gradient(45deg, #ffffff, #ffffff);
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* animation: gradient 3s ease infinite; */
}

.gradient-text:hover {
  animation: gradient 5s ease infinite;
}